<template>
  <div class="main">
    <el-table 
    :data="dateData" 
    style="width: 90%"
    :row-style="rowStyle"
    :cell-style="cellStyle"
    :header-cell-style="headerStyle"
    >
        <el-table-column prop="date" label="时间"/>
        <el-table-column label="操作" width="80">
          <template #default="scope">
            <el-button size="mini" type="primary" @click="closeBtnTwo(scope.$index, scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
    </el-table>
    <div style="width:100%;padding:20px 20px 0px 0px;display:flex;flex-direction:column">
      <!-- <div style="margin:auto">
        <el-pagination 
          background 
          @current-change='pagingReq' 
          layout="prev, pager, next" 
          :current-page="pagNum"
          :total="pagingNum">
        </el-pagination>
      </div> -->
      <div>
        <!-- <el-button style="float: right;margin-left:20px;" type="primary" @click="closeBtnTwo">确认</el-button> -->
        <!-- <el-button style="float: right;"  @click="closeBtnOne">取消</el-button> -->
      </div>

    </div>
  </div>
</template>

<script>
import { defineComponent ,ref } from 'vue'
import { useStore  } from'vuex'
export default defineComponent({
  setup(props,{emit}) {
   let year = new Date().getFullYear()
    let dateData = ref()
    let dateYear = year - (+(window.sessionStorage.getItem('hisdataYear'))+1)
    dateData.value = [{reqTimeMin:year + '-01-01',reqTimeMax:year + '-03-31',date:year + '-01-01 至 '+year + '-03-31'}]
    for(let i = 1; i <= dateYear; i++){
      let data = [{reqTimeMin:year-i + '-10-01',reqTimeMax:year-i + '-12-31',date:year-i + '-10-01 至 '+(year-i) + '-12-31'},
        {reqTimeMin:year-i + '-07-01',reqTimeMax:year-i + '-09-30',date:year-i + '-07-01 至 '+(year-i) + '-09-30'},
        {reqTimeMin:year-i + '-04-01',reqTimeMax:year-i + '-06-30',date:year-i + '-04-01 至 '+(year-i) + '-06-30'},
        {reqTimeMin:year-i + '-01-01',reqTimeMax:year-i + '-03-31',date:year-i + '-01-01 至 '+(year-i) + '-03-31'}]
      dateData.value.push(...data)
    }
    if(new Date().getMonth() >= 9){
      dateData.value.unshift({reqTimeMin:year + '-04-01',reqTimeMax:year + '-06-30',date:year + '-04-01至'+year + '-06-30'})
      dateData.value.unshift({reqTimeMin:year + '-07-01',reqTimeMax:year + '-09-30',date:year + '-07-01至'+year + '-09-30'})
      dateData.value.unshift({reqTimeMin:year + '-10-01',reqTimeMax:year + '-12-31',date:year + '-10-01至'+year + '-12-31'}) 
    }else if(new Date().getMonth() >= 6){
      dateData.value.unshift({reqTimeMin:year + '-04-01',reqTimeMax:year + '-06-30',date:year + '-04-01至'+year + '-06-30'})
      dateData.value.unshift({reqTimeMin:year + '-07-01',reqTimeMax:year + '-09-30',date:year + '-07-01至'+year + '-09-30'})
    }else if(new Date().getMonth() >= 3){
      dateData.value.unshift({reqTimeMin:year + '-04-01',reqTimeMax:year + '-06-30',date:year + '-04-01至'+year + '-06-30'})
    }
    let store = useStore()
    store.commit('setSea',dateData.value)
    let pagingNum = ref(1000) //分页数
    let pagNum = ref(1)       //当前页
    const closeBtnOne = ()=>{
      emit('emit-close',{statue:0})
    }
    const closeBtnTwo = (a,b)=>{
      emit('emit-close',{statue:1,type:'季',reqTimeMin:b.reqTimeMin,reqTimeMax:b.reqTimeMax,value:b.date,a})
    }
    const rowStyle = ()=>{
      
      let style = {}
      style.padding = 0
      return style
    }
    const cellStyle = ()=>{
      let style = {}
      style.height='30px'
      style.padding = '5px 0';
      // style['text-align'] = "center"
      style['font-size'] = "17px"
      return style
    }
    const headerStyle = ()=>{
      let style = {}
      // style['text-align'] = "center"
      style['font-size'] = "18px"
      return style
    }
    const pagingReq = (num)=>{  //分页请求
      pagNum.value = num;
    }
    return {
      dateData,
      closeBtnOne,
      closeBtnTwo,
      rowStyle,
      cellStyle,
      headerStyle,
      pagingNum,
      pagNum,
      pagingReq,

    }
  }
})
</script>

<style scoped>
.main{
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
</style>