<template>
  <div class="main">
    <el-table 
    :data="dateData" 
    style="width: 90%"
    :row-style="rowStyle"
    :cell-style="cellStyle"
    :header-cell-style="headerStyle"
    >
        <el-table-column prop="data" label="时间"/>
        <el-table-column label="操作" width="80" >
          <template #default="scope">
            <el-button size="mini" type="primary" @click="closeBtnTwo(scope.$index, scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
    </el-table>
    <div style="width:100%;padding:20px 20px 0px 0px;display:flex;flex-direction:column">
      <div style="margin:auto;margin-right:0px">
        <el-pagination 
          background 
          @current-change='pagingReq' 
          layout="prev, pager, next" 
          :current-page="pagNum"
          :total="pagingNum">
        </el-pagination>
      </div>
      <div>
        <!-- <el-button style="float: right;margin-left:20px;" type="primary" @click="closeBtnTwo">确认</el-button> -->
        <!-- <el-button style="float: right;"  @click="closeBtnOne">取消</el-button> -->
      </div>

    </div>
  </div>
</template>

<script>
import { defineComponent ,ref } from 'vue'
import { useStore  } from'vuex'
export default defineComponent({
  setup(props,{emit}) {
    let dateData = ref([])
    let dateList = []
    let a = new Date()
    let c = new Date(+(window.sessionStorage.getItem('hisdataYear'))+1 + '-01-01')
    let b = []
    for(let i = a.getFullYear();i>=c.getFullYear();i--){
      if(i === a.getFullYear()){
        for(let j = a.getMonth()+1;j>0;j--){
          if(j === 2){
            b.push({reqTimeMin:i +'-' + (j>9?j:'0'+j)+'-01',reqTimeMax:i +'-' + (j>9?j:'0'+j)+'-28',data:i +'-' + (j>9?j:'0'+j)+'-01' +' 至 '+i +'-' + (j>9?j:'0'+j)+'-28'})
          }else if(j === 4 || j=== 6 || j=== 9 || j === 11 ){
            b.push({reqTimeMin:i +'-' + (j>9?j:'0'+j)+'-01',reqTimeMax:i +'-' + (j>9?j:'0'+j)+'-30',data:i +'-' + (j>9?j:'0'+j)+'-01' +' 至 '+i +'-' + (j>9?j:'0'+j)+'-30'})
          }else{
            b.push({reqTimeMin:i +'-' + (j>9?j:'0'+j)+'-01',reqTimeMax:i +'-' + (j>9?j:'0'+j)+'-31',data:i +'-' + (j>9?j:'0'+j)+'-01' +' 至 '+i +'-' + (j>9?j:'0'+j)+'-31'})
          }
        }
      }else{
        for(let j = 12;j>0;j--){
          if(j === 2){
            b.push({reqTimeMin:i +'-' + (j>9?j:'0'+j)+'-01',reqTimeMax:i +'-' + (j>9?j:'0'+j)+'-28',data:i +'-' + (j>9?j:'0'+j)+'-01' +' 至 '+i +'-' + (j>9?j:'0'+j)+'-28'})
          }else if(j === 4 || j=== 6 || j=== 9 || j === 11 ){
            b.push({reqTimeMin:i +'-' + (j>9?j:'0'+j)+'-01',reqTimeMax:i +'-' + (j>9?j:'0'+j)+'-30',data:i +'-' + (j>9?j:'0'+j)+'-01' +' 至 '+i +'-' + (j>9?j:'0'+j)+'-30'})
          }else{
            b.push({reqTimeMin:i +'-' + (j>9?j:'0'+j)+'-01',reqTimeMax:i +'-' + (j>9?j:'0'+j)+'-31',data:i +'-' + (j>9?j:'0'+j)+'-01' +' 至 '+i +'-' + (j>9?j:'0'+j)+'-31'})
          }
        }
      }
    }
    dateList = b
    dateData.value = dateList.slice(0,13)


    const closeBtnOne = ()=>{
      emit('emit-close',{statue:0})
    }
    const closeBtnTwo = (a,b)=>{
      emit('emit-close',{statue:1,type:'月',reqTimeMin:b.reqTimeMin,reqTimeMax:b.reqTimeMax,value:b.date,a})
    }
    let store = useStore()
    store.commit('setMonth',dateList)
    const rowStyle = ()=>{
      let style = {}
      style.padding = 0
      return style
    }
    const cellStyle = ()=>{
      let style = {}
      style.height='30px'
      style.padding = '5px 0';
      // style['text-align'] = "center"
      style['font-size'] = "17px"
      return style
    }
    const headerStyle = ()=>{
      let style = {}
      // style['text-align'] = "center"
      style['font-size'] = "18px"
      return style
    }
    let pagingNum = ref(dateList.length/13*10) //分页数
    let pagNum = ref(1)       //当前页
    const pagingReq = (num)=>{  //分页请求
      pagNum.value = num;
      dateData.value = dateList.slice(num==1?0:13*(num-1),13*num)
      // console.log(dateList)
    }
    return {
      dateData,
      closeBtnOne,
      closeBtnTwo,
      rowStyle,
      cellStyle,
      headerStyle,
      pagingNum,
      pagNum,
      pagingReq,

    }
  }
})
</script>

<style scoped>
.main{
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
</style>