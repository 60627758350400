<template>
  <div class="main">
    <el-table 
    :data="dateData" 
    style="width:95%"
    :row-style="rowStyle"
    :cell-style="cellStyle"
    :header-cell-style="headerStyle"
    >
      <!-- <el-table-column type="selection" width="55" /> -->
      <el-table-column label="选择" width="60">
        <template #default="scope">
          <el-checkbox @change="changeState()" v-model="scope.row.state"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="data" label="时间"/>
    </el-table>
    <div style="width:100%;padding:20px 20px 0px 0px;display:flex;flex-direction:column">
      <div style="margin:auto;margin-right:0px">
        <el-pagination 
          background 
          @current-change='pagingReq' 
          layout="prev, pager, next" 
          :current-page="pagNum"
          :total="pagingNum">
        </el-pagination>
      </div>
      <div>
        <el-button style="float: right;margin-left:20px;" type="primary" :disabled='disState' @click="closeBtnTwo">确认</el-button>
        <el-button style="float: right;"  @click="closeBtnOne">取消</el-button>
      </div>

    </div>
  </div>
</template>

<script>
import { defineComponent ,ref,watch } from 'vue'
import {postData} from'@a'
import {useRoute} from'vue-router'
// import store from'@/store'
export default defineComponent({
  setup(props,{emit}) {
    let route = useRoute()
    let dateData = ref([])
    let disState = ref(true)
    let dateMin = ''
    let titleDate = ''
    const closeBtnOne = ()=>{
      emit('emit-close',{statue:0})
    }
    const closeBtnTwo = ()=>{
      emit('emit-close',{statue:1,type:'班次',reqTimeMin:dateMin,titleDate})
    }
    const changeState = ()=>{
      dateMin = ''
      let dataList = []
      let j = 0
      for(let i = 0;i<dateData.value.length;i++){
        if(dateData.value[i].state){
          if(j !== 0){
            dateMin +=','
          }
          j++
          dateMin += dateData.value[i].reqTimeMin
          dataList.push(dateData.value[i])
        }
      }
      if(dataList.length){
      titleDate = dataList[dataList.length - 1].beginTime + '至' + dataList[0].endTime?dataList[0].endTime:''
      }
      if(dateMin !== ''){
        disState.value = false
      }else{
        disState.value = true
      }
    }
    watch(()=>dateData.value,()=>{
      changeState()
    })
    const rowStyle = ()=>{
      
      let style = {}
      style.padding = 0
      return style
    }
    const cellStyle = ()=>{
      let style = {}
      style.height='30px'
      style.padding = '5px 0';
      style['text-align'] = "center"
      style['font-size'] = "17px"
      return style
    }
    const headerStyle = ()=>{
      let style = {}
      style['text-align'] = "center"
      style['font-size'] = "18px"
      return style
    }
    let pagingNum = ref(10) //分页数
    let pagNum = ref(1)       //当前页
    const pagingReq = (num)=>{  //分页请求
      pagNum.value = num;
      infoShop()
    }
    const infoShop = async () => {
      let param = {
        shopId:route.query.shopId,
        pageSize:13,
        pageNo:pagNum.value,
        year:new Date().getFullYear()-1
      }
      try {
        let res = await postData('shop/dutys.json',param)
        pagingNum.value = res.data.total/13*10
        let rowsNumber = 0
        res.data.records.forEach(e => {
          e.state = false
          e.reqTimeMin = e.dutyId
          e.reqTimeMax = e.endTime?e.endTime:''
          e.pagNum = rowsNumber++
          e.data = e.beginTime +'至'+ e.reqTimeMax
        });
        dateData.value = res.data.records
      } catch (err) {
        console.log(err)
      }
    }
    infoShop()
    return {
      dateData,
      closeBtnOne,
      closeBtnTwo,
      rowStyle,
      cellStyle,
      headerStyle,
      // handleSelectionChange,
      pagingNum,
      pagNum,
      pagingReq,
      disState,
      changeState,

    }
  }
})
</script>

<style scoped>
.main{
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
</style>