<template>
  <div class="main">
    <el-table 
    :data="dateData" 
    style="width: 90%"
    :row-style="rowStyle"
    :cell-style="cellStyle"
    :header-cell-style="headerStyle"
    >
        <el-table-column prop="data" label="时间"/>
        <el-table-column label="操作" width="80">
          <template #default="scope">
            <el-button size="mini" type="primary" @click="closeBtnTwo(scope.$index, scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
    </el-table>
    <div style="width:100%;padding:20px 20px 0px 0px;display:flex;flex-direction:column">
      <div style="margin:auto;margin-right:0px">
        <el-pagination 
          background 
          @current-change='pagingReq' 
          layout="prev, pager, next" 
          :current-page="pagNum"
          :total="pagingNum">
        </el-pagination>
      </div>
      <div>
        <!-- <el-button style="float: right;margin-left:20px;" type="primary" @click="closeBtnTwo">确认</el-button> -->
        <!-- <el-button style="float: right;"  @click="closeBtnOne">取消</el-button> -->
      </div>

    </div>
  </div>
</template>

<script>
import { defineComponent ,ref } from 'vue'
export default defineComponent({
  setup(props,{emit}) {
    let dateData = ref([])
    const closeBtnOne = ()=>{
      emit('emit-close',{statue:0})
    }
    const closeBtnTwo = (a,b)=>{
      emit('emit-close',{statue:1,type:'周',reqTimeMin:b.reqTimeMin,reqTimeMax:b.reqTimeMax,a})
    }
    const rowStyle = ()=>{
      
      let style = {}
      style.padding = 0
      return style
    }
    const cellStyle = ()=>{
      let style = {}
      style.height='30px'
      style.padding = '5px 0';
      // style['text-align'] = "center"
      style['font-size'] = "17px"
      return style
    }
    const headerStyle = ()=>{
      let style = {}
      // style['text-align'] = "center"
      style['font-size'] = "18px"
      return style
    }

    //要生成的几年的数据
    let yearNum = new Date().getFullYear() - window.sessionStorage.getItem('hisdataYear')
    let a = new Date()
    let time = a.getFullYear()+'-'+((a.getMonth()+1)>9?(a.getMonth()+1):('0'+(a.getMonth()+1)))+'-'+(a.getDate()>9?a.getDate():'0'+a.getDate())
    let dateList = []
    const timeWeek = (param)=>{
      switch(new Date(param.reqTimeMin).getMonth()){
        case 0 :
          param.reqTimeMin = (new Date(time).getFullYear()-1)+'-12-31'
          break;
        case 1 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
          break;
        case 2 :
          var year = new Date(time).getFullYear()
          if(year%4 == 0 && year%100 != 0 || year%400 == 0){
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-29'
          }else{
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-28'
          }
          break;
        case 3 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
          break;
        case 4 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-30'
          break;
        case 5 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
          break;
        case 6 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-30'
          break;
        case 7 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
          break;
        case 8 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
          break;
        case 9 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-30'
          break;
        case 10 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
          break;
        case 11 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-30'
          break;
        default : 
          param.reqTimeMin =  new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-'+((new Date(time).getDate()-1)>9?(new Date(time).getDate()-1):'0'+(new Date(time).getDate()-1))
          break
      }
      return param.reqTimeMin
    }
    const forDate = (param,forNum)=>{
      for(let i = 1;i < forNum;i++){
        if(new Date(param.reqTimeMin).getDate() !== 1){
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth()+1)>9?(new Date(time).getMonth()+1):('0'+(new Date(time).getMonth()+1)))+'-'+((new Date(time).getDate()-1)>9?(new Date(time).getDate()-1):'0'+(new Date(time).getDate()-1))
          time = param.reqTimeMin
          if(i === 6 && new Date(time).getDate() != 1){
            time = new Date(time).getFullYear()+'-'+((new Date(time).getMonth()+1)>9?(new Date(time).getMonth()+1):('0'+(new Date(time).getMonth()+1)))+'-'+((new Date(time).getDate()-1)>9?(new Date(time).getDate()-1):'0'+(new Date(time).getDate()-1))
          }else if(i === 6 && new Date(time).getDate() == 1){
            time = timeWeek(param)
          }
        }else{
          param.reqTimeMin = time = timeWeek(param)
          if(i === 6){
            time = new Date(time).getFullYear()+'-'+((new Date(time).getMonth()+1)>9?(new Date(time).getMonth()+1):('0'+(new Date(time).getMonth()+1)))+'-'+((new Date(time).getDate()-1)>9?(new Date(time).getDate()-1):'0'+(new Date(time).getDate()-1))
          }
        }
      }
    }
    let param = {reqTimeMin:'',reqTimeMax:'',data:''}
    param.reqTimeMax = time
    param.reqTimeMin = time
    switch(new Date(time).getDay()){
      case 0 :
        forDate(param,7)
        break;
      case 1 :
        param.reqTimeMin = time
        break;
      case 2 :
        forDate(param,2)
        break;
      case 3 :
        forDate(param,3)
        break;
      case 4 :
        forDate(param,4)
        break;
      case 5 :
        forDate(param,5)
        break;
      case 6 :
        forDate(param,6)
        break;
      default : 
        param.reqTimeMin = time
    }
    param.data = param.reqTimeMin+' 至 '+param.reqTimeMax
    dateList.push(param)

    while(new Date(time).getFullYear()>a.getFullYear()-yearNum){
      let param = {reqTimeMin:'',reqTimeMax:'',data:''}
      param.reqTimeMax = time
      param.reqTimeMin = time
      forDate(param,7)
      param.data = param.reqTimeMin+' 至 '+param.reqTimeMax
      dateList.push(param)
    }
    dateData.value = dateList.slice(0,13)
    let pagingNum = ref(dateList.length/13*10) //分页数
    let pagNum = ref(1)       //当前页
    const pagingReq = (num)=>{  //分页请求
      pagNum.value = num;
      dateData.value = dateList.slice(num==1?0:13*(num-1),13*num)
    }
    if(dateList.length){
      dateList[dateList.length-1].reqTimeMin = new Date(dateList[dateList.length-1].reqTimeMax).getFullYear() + '-01-01'
      dateList[dateList.length-1].data = dateList[dateList.length-1].reqTimeMin + ' 至 ' + dateList[dateList.length-1].reqTimeMax
    }
    return {
      dateData,
      closeBtnOne,
      closeBtnTwo,
      rowStyle,
      cellStyle,
      headerStyle,
      pagingNum,
      pagNum,
      pagingReq,

    }
  }
})
</script>

<style scoped>
.main{
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
</style>