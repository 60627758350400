<template>
  <div class="main">
      <div class="block">
        <span class="demonstration">开始时间：</span>
        <el-date-picker v-model="startTime" type="date" placeholder="开始时间" />
      </div>
      <br>
      <div class="block">
        <span class="demonstration">结束时间：</span>
        <el-date-picker v-model="endTime" type="date" placeholder="结束时间" />
      </div>
      <p class="block titie-text">{{hisdataYear}}之前的数据无法跨年查询</p>
      <div style="width:100%;padding:20px 20px 0px 0px;display:flex;justify-content:end">
        <el-button  type="info" @click="closeBtnOne">取消</el-button>
        <el-button type="primary" @click="closeBtnTwo">确认</el-button>
      </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import {ElMessage} from'element-plus'
export default defineComponent({
  setup(props,{emit}) {
    const startTime = ref(null)
    const endTime = ref(null)
    const hisdataYear = ref(+(window.sessionStorage.getItem('hisdataYear'))+1)
    const closeBtnOne = ()=>{
      emit('emit-close',{statue:0})
    }
    const closeBtnTwo = ()=>{
      if(startTime.value === null || endTime.value === null){
        ElMessage('请选择时间范围！')
        return
      }
      let state = [startTime.value,endTime.value]
      if(endTime.value.getTime() < startTime.value.getTime()){
        state = [endTime.value,startTime.value]
      }
      console.log(new Date(state[0]).getFullYear())
      if(new Date(state[0]).getFullYear() < 2016){
        ElMessage('2016之前无数据')
        return
      }
      if(new Date(state[0]).getFullYear() < hisdataYear.value && new Date(state[0]).getFullYear() !== new Date(state[1]).getFullYear()){
        ElMessage(hisdataYear.value + '之前数据查询不能跨年')
        return
      }
      emit('emit-close',{statue:1,type:'自定义',value:state})
    }
    return {
      closeBtnOne,
      closeBtnTwo,
      startTime,
      endTime,
      hisdataYear,
      // ...toRefs(state),

    }
  }
})
</script>

<style scoped>
.main{
  display: flex;
  align-items: center;
  padding-top: 35px;
  flex-direction: column;
  overflow: hidden;
}
.block{
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titie-text{
  margin: 14px 0 10px -0;
  font-size: 13px;
  color: tomato;
}
</style>