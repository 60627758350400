<template>
  <div class="main">
    <el-table 
    :data="dateData" 
    style="width: 90%"
    :row-style="rowStyle"
    :cell-style="cellStyle"
    :header-cell-style="headerStyle"
    >
      <!-- <el-table-column type="selection" width="55" /> -->
      <el-table-column label="选择" width="80">
        <template #default="scope">
          <el-checkbox @change="changeState()" v-model="scope.row.state"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="data" label="时间"/>
    </el-table>
    <div style="width:100%;padding:20px 20px 0px 0px;display:flex;flex-direction:column">
      <div style="margin:auto;margin-right:0px">
        <el-pagination 
          background 
          @current-change='pagingReq' 
          layout="prev, pager, next" 
          :current-page="pagNumber"
          :total="pagingNum">
        </el-pagination>
      </div>
      <div style="padding-top:10px">
        <el-button style="float: right;margin-left:20px;" type="primary" :disabled='disState' @click="closeBtnTwo()">确认</el-button>
        <el-button style="float: right;"  @click="closeBtnOne">取消</el-button>
      </div>

    </div>
  </div>
</template>

<script>
import { defineComponent ,ref,watch } from 'vue'
export default defineComponent({
  setup(props,{emit}) {
    let dateData = ref([])
    let disState = ref(true)
    // 格式化日期
    // const formattingData = date=> date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate()
    let dateMin = ''
    let dateMax = ''
    const closeBtnOne = ()=>{
      emit('emit-close',{statue:0})
    }
    const closeBtnTwo = ()=>{
      if(dateMin === '') return
      if(dateMax === '' || dateMax === dateMin){
        emit('emit-close',{statue:1,type:'日',reqTimeMin:dateMin,reqTimeMax:dateMin})
      }else{
        emit('emit-close',{statue:1,type:'日',reqTimeMin:dateMin,reqTimeMax:dateMax})
      }
    }
    const changeState = ()=>{
      let selectNum = []
      for(let i = 0;i<dateData.value.length;i++){
        if(dateData.value[i].state)
        selectNum.push(dateData.value[i])
      }
      if(selectNum.length > 1){
        disState.value = false
        dateMin = selectNum[selectNum.length-1].reqTimeMax
        dateMax = selectNum[0].reqTimeMin
      }else if(selectNum.length == 1){
        disState.value = false
        dateMin = selectNum[0].reqTimeMin
        dateMax = ''
      }else{
        disState.value = true
        dateMin = ''
        dateMax = ''
        return
      }
      if(selectNum.length>=2){
        for(let i = 0;i<dateData.value.length;i++){
          if(dateData.value[i].pagNum>selectNum[0].pagNum && dateData.value[i].pagNum<selectNum[selectNum.length-1].pagNum)
          dateData.value[i].state = true
        }
      }
    }
    watch(()=>dateData.value,()=>{
      changeState()
    })
    const rowStyle = ()=>{
      
      let style = {}
      style.padding = 0
      return style
    }
    const cellStyle = ()=>{
      let style = {}
      style.height='30px'
      style.padding = '5px 0';
      style['text-align'] = "center"
      style['font-size'] = "16px"
      return style
    }
    const headerStyle = ()=>{
      let style = {}
      style['text-align'] = "center"
      style['font-size'] = "17px"
      return style
    }
    let yearNum = new Date().getFullYear() - window.sessionStorage.getItem('hisdataYear')
    let a = new Date()
    // let time = a.getFullYear()+'-'+(a.getMonth()+1)+'-'+(a.getDate()>9?a.getDate():'0'+a.getDate())
    let time = a.getFullYear()+'-'+((a.getMonth()+1)>9?(a.getMonth()+1):('0'+(a.getMonth()+1)))+'-'+(a.getDate()>9?a.getDate():'0'+a.getDate())
    let dateList = []
    const timeDate = (param)=>{
      switch(new Date(param.reqTimeMin).getMonth()){
        case 0 :
          param.reqTimeMin = (new Date(time).getFullYear()-1)+'-12-31'
          break;
        case 1 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
          break;
        case 2 :
          var year = new Date(time).getFullYear()
          if(year%4 == 0 && year%100 != 0 || year%400 == 0){
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-29'
          }else{
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-28'
          }
          break;
        case 3 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
          break;
        case 4 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-30'
          break;
        case 5 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
          break;
        case 6 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-30'
          break;
        case 7 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
          break;
        case 8 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
          break;
        case 9 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-30'
          break;
        case 10 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
          break;
        case 11 :
          param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-30'
          break;
        default : 
          param.reqTimeMin =  time
          break
      }
      return param.reqTimeMin
    }
    let pagNum = -1
    while(new Date(time).getFullYear()>a.getFullYear()-yearNum){
      let param = {reqTimeMin:'',reqTimeMax:'',data:'',state:false,pagNum}
      param.data = param.reqTimeMin = param.reqTimeMax = time
      if(pagNum !== -1){
        if(new Date(param.reqTimeMin).getDate() !== 1){
          let data = param.reqTimeMin = param.reqTimeMax = time = new Date(time).getFullYear()+'-'+((new Date(time).getMonth()+1)>9?(new Date(time).getMonth()+1):('0'+(new Date(time).getMonth()+1)))+'-'+((new Date(time).getDate()-1)>9?(new Date(time).getDate()-1):'0'+(new Date(time).getDate()-1))
          param.data = data
        }else{
          let data = param.reqTimeMin = param.reqTimeMax = time = timeDate(param)
          param.data = data
        }
      }
      pagNum++
      if(pagNum>=13) pagNum = 0
      param.pagNum = pagNum
      // param.data = param.data + ' (0:0:0 - 23:59:59)'
      dateList.push(param)
    }
    dateList.pop()
    dateData.value = dateList.slice(0,13)
    let pagingNum = ref(dateList.length/13*10) //分页数
    let pagNumber = ref(1)       //当前页
    const pagingReq = (num)=>{  //分页请求
      pagNumber.value = num;
      dateData.value = dateList.slice(num==1?0:13*(num-1),13*num)
      // console.log(dateList)
    }
    return {
      dateData,
      closeBtnOne,
      closeBtnTwo,
      rowStyle,
      cellStyle,
      headerStyle,
      // handleSelectionChange,
      pagingNum,
      pagNumber,
      pagingReq,
      disState,
      changeState,

    }
  }
})
</script>

<style scoped>
.main{
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: end; */
  padding-left: 40px;
  overflow: hidden;
}
</style>