<template>
  <div class="main">
    <el-table 
    :data="dateData" 
    style="width: 90%"
    :row-style="rowStyle"
    :cell-style="cellStyle"
    :header-cell-style="headerStyle"
    >
        <el-table-column prop="date" label="时间"/>
        <el-table-column label="操作" width="80">
          <template #default="scope">
            <el-button size="mini" type="primary" @click="closeBtnTwo(scope.$index, scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
    </el-table>
    <div style="width:100%;padding:20px 20px 0px 0px;display:flex;flex-direction:column">
      <!-- <div style="margin:auto">
        <el-pagination 
          background 
          @current-change='pagingReq' 
          layout="prev, pager, next" 
          :current-page="pagNum"
          :total="pagingNum">
        </el-pagination>
      </div> -->
      <div>
        <!-- <el-button style="float: right;margin-left:20px;" type="primary" @click="closeBtnTwo">确认</el-button> -->
        <!-- <el-button style="float: right;"  @click="closeBtnOne">取消</el-button> -->
      </div>

    </div>
  </div>
</template>
<!-- <template>
  <div class="main">
    <el-table 
    :data="dateData" 
    style="width: 50%"
    :row-style="rowStyle"
    :cell-style="cellStyle"
    :header-cell-style="headerStyle"
    >
        <el-table-column label="时间">
          <template #default="scope">
            {{scope.row.date.substr(0,4)}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template #default="scope">
            <el-button size="mini" type="primary" @click="closeBtnTwo(scope.$index, scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
    </el-table>
    <div style="width:100%;padding:20px 20px 0px 0px;display:flex;flex-direction:column">
    </div>
  </div>
</template> -->

<script>
import { defineComponent ,ref } from 'vue'
// import store from'@/store'
import { useStore  } from'vuex'
export default defineComponent({
  setup(props,{emit}) {
    let year = new Date().getFullYear()
    let yearDataDate = [
      {
        reqTimeMin:new Date().getFullYear(),
        reqTimeMax:new Date().getFullYear(),
        date:(new Date().getFullYear())+'-01-01 至 '+ new Date().getFullYear()+ '-' +((new Date().getMonth()+1)>9?(new Date().getMonth()+1):('0'+(new Date().getMonth()+1)))+ '-' +((new Date().getDate())>9?(new Date().getDate()):('0'+(new Date().getDate())))
      }]
    let dateYear = year - 2016
    for(let i = 1; i <= dateYear; i++){
      let data = {reqTimeMin:new Date().getFullYear()-i,reqTimeMax:new Date().getFullYear()-i,date:(new Date().getFullYear()-i)+'-01-01 至 '+(new Date().getFullYear()-i)+'-12-31'}
      yearDataDate.push(data)
    }
    let store = useStore()
    store.commit('setYear',yearDataDate)
    let dateData = ref(store.state.yearDate)
    let pagingNum = ref(1000) //分页数
    let pagNum = ref(1)       //当前页
    const closeBtnOne = ()=>{
      emit('emit-close',{statue:0})
    }
    const closeBtnTwo = (a,b)=>{
      emit('emit-close',{statue:1,type:'年',reqTimeMin:b.reqTimeMin,reqTimeMax:b.reqTimeMax,value:b.date,a})
    }
    const rowStyle = ()=>{
      let style = {}
      style.padding = 0
      return style
    }
    const cellStyle = ()=>{
      let style = {}
      style.height='30px'
      style.padding = '5px 0';
      // style['text-align'] = "center"
      style['font-size'] = "17px"
      return style
    }
    const headerStyle = ()=>{
      let style = {}
      // style['text-align'] = "center"
      style['font-size'] = "18px"
      return style
    }
    const pagingReq = (num)=>{  //分页请求
      pagNum.value = num;
    }
    return {
      dateData,
      closeBtnOne,
      closeBtnTwo,
      rowStyle,
      cellStyle,
      headerStyle,
      pagingNum,
      pagNum,
      pagingReq,

    }
  }
})
</script>

<style scoped>
.main{
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
</style>