<template>
  <div>
    <header v-show="headerState" style="padding-top:10px">
      <div class="shop-name">
        &nbsp;&nbsp;<span v-if="shopValue==='全部店铺'">{{shopName}}-</span>营业报告
      </div>
      <div style="display:flex">
        <el-select 
          v-model="shopValue" 
          placeholder="Select"
          @change='selectChangFun'
        >
          <el-option
            v-for="item in shopList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div  class="open-select">
          <div @click='clickCut(0)' style="cursor: pointer;font-size: 18px;font-weight: 700;width:40px;height:100%;line-height:200%;text-align:center;border-right:1px solid #E4E4E4">&lt;</div>
          <div style="padding:0 20px" v-if="$route.query.dateType && $route.query.dateType=='班次'">{{titleDate}}</div>
          <div style="padding:0 20px" v-else-if="$route.query.dateType && $route.query.dateType=='年'">{{$route.query.dateMin}}</div>
          <div style="padding:0 20px" v-else-if="$route.query.dateMin">{{$route.query.dateMin + ($route.query.dateMax ? "至" + $route.query.dateMax : '')}}</div>
          <div style="padding:0 20px" v-else>
            {{new Date().getFullYear()+'-'+((new Date().getMonth()+1)>9?(new Date().getMonth()+1):('0'+(new Date().getMonth()+1)))+'-'+(new Date().getDate()>9?new Date().getDate():('0'+new Date().getDate()))}}
          </div>
          <div style="" @click="dialogVisibleClick()" class="select-bottom">&lt;</div>  
          <div 
          @click='clickCut(1)' style="cursor: pointer;font-size: 18px;font-weight: 700;width:40px;height:100%;line-height:200%;text-align:center;border-right:1px solid #E4E4E4">&gt;</div>
        </div>
        <el-dialog
          v-model="dialogVisible"
          title="选择时间"
          width="476px"
          top="20px"
          :close-on-press-escape="false"
          :destroy-on-close='true'
        >
          <p v-if="dateText!=='自定义'" class="top-text-hint">跨年查询请自定义查询</p>
        <!-- border-top:1px solid #bbb; -->
          <div style="font-size:14px;">
            <div class="selese-top-hint" v-if="selectState">          
              选择方式：&nbsp;&nbsp;<el-select class="flex1" v-model="dateText" placeholder="Select">
                <el-option
                  v-for="item in dateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="selese-top-hint" v-else>          
              选择方式：&nbsp;&nbsp;<el-select class="flex1" v-model="dateText" placeholder="Select">
                <el-option
                  v-for="item in dateList1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- <keep-alive> -->
              <component :is="selectCom" @emit-close="closeSelect"/>
            <!-- </keep-alive> -->

          </div>
        </el-dialog>
      </div>
    </header>
    <main style="margin-top:-10px">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component"></component>
        </keep-alive>
      </router-view>
    </main>
  </div>
</template>
<script>
import { ref, defineComponent,watch,shallowRef  ,computed,} from 'vue'
// import TagetShop from'@c/togetherShop.vue'
// import AloneShop from'@c/aloneShop.vue'
import Custom from"@c/select/custom.vue"
import MoreSelect from"@c/select/moreSelect.vue"
import OneSelect from"@c/select/oneSelect.vue"
import MonthSelect from"@c/select/monthSelect.vue"
import SeaSelect from"@c/select/seaSelect.vue"
import WeekSelect from"@c/select/weekSelect.vue"
import ClassSelect from"@c/select/classSelect.vue"
// import DateSelect from'@c/dateSelect.vue'
import { useRouter,useRoute } from 'vue-router'
import  {postData} from '@a/index.js'
// import { useStore } from 'vuex'
// import store from'@/store'
// import DeductGoods from'./report/deductGoods'
// import DeductStaff from'./report/deductStaff'
import { useStore  } from'vuex'
export default defineComponent({
  components:{
    Custom,MoreSelect,OneSelect,MonthSelect,SeaSelect,WeekSelect,ClassSelect
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    let store = useStore()
    let shopName = ref(window.localStorage.getItem('shopName'))
    let shopList= ref([
      {
        value: '全部店铺',
        label: '全部店铺'
      },
    ])
    let dialogVisible = ref(false)
    let date = new Date()
    let reqTime = ref(route.query.dateMin?route.query.dateMin:(date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):'0'+(date.getDate())))
    let reqTimeMin = ref(reqTime.value)
    let reqTimeMax = ref('')
    const dateList = ref([
      { value: '日',
        label: '日'
      },
      { value: '周',
        label: '周'
      },
      { value: '月',
        label: '月'
      },
      { value: '季',
        label: '季' 
      },
      { value: '年',
        label: '年'
      },
      { value: '自定义',
        label: '自定义'
      }
    ])
    const dateList1 = ref([
      { value: '日',
        label: '日'
      },
      { value: '班次',
        label: '班次'
      },
      { value: '周',
        label: '周'
      },
      { value: '月',
        label: '月'
      },
      { value: '季',
        label: '季' 
      },
      { value: '年',
        label: '年'
      },
      { value: '自定义',
        label: '自定义'
      }
    ])
    let selectState = ref(true)
    let dateText = ref(route.query.dateType?route.query.dateType:'日')
    let headerState = ref(true)
    watch(()=>route.query,(a,b)=>{
      watchRouteQuery1(a,b)
    })
    const watchRouteQuery1 = (a,b)=>{
      if(route.name === 'deductStaff' || route.name === 'deductGoods'){
        headerState.value = false
      }else{
        headerState.value = true
      }
      if(route.name === 'togetherShop'){
        if(dateText.value === '班次'){
          dateText.value = '日'
        }
        selectState.value = true
      }else{
        if(route.query.shopName==''&&route.query.shopId == ''){
          router.push({name:'home'})
          shopValue.value = '全部店铺'
        }else{
          shopValue.value = route.query.shopName
          if(dateText.value === '班次'){
            reqTime.value = '班次'+route.query.dateMin
          }else{
            reqTime.value = route.query.dateMin + '至' +route.query.dateMax
          }
        }
        selectState.value = false
      }
      if(a.shopId && a.shopId != b.shopId){
        infoClassData()
      }
    }
    const reqTimeFun = computed(()=>reqTime.value.length>40?reqTime.value.substring(0,38)+'...':reqTime.value)
    let shopValue =  ref(route.query.shopName?route.query.shopName:'全部店铺')
    watch(()=>route.query,(val)=>{
      if(route.query.shopName === shopValue.value) return
      if(!val.shopName){
        shopValue.value = '全部店铺'
      }
    })
    let selectCom = shallowRef(MoreSelect)
    const selectChangFun = (val)=>{

      let data = {}
      data.shopName = shopList.value.map((arr)=>{
        if(arr.value === val)
        return arr.label
      })
      data.shopId = shopList.value.map((arr)=>{
        if(arr.value === val)
        return val
      })

      if(dateText.value === '班次' || val === '全部店铺'){
        dateText.value = '日'
        reqTimeMin.value = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):'0'+(date.getMonth() + 1)) + '-' + ((date.getDate())>9?(date.getDate()):'0'+(date.getDate()))
        reqTimeMax.value = ''
        reqTime.value = reqTimeMin.value
      }
      data.fff = 1
      routerTo(data)
    }
    let shopNameQuery = ''
    let shopIdQuery = ''
    const routerTo = (val)=>{
      console.log(val)
      if(shopValue.value === '全部店铺'){
        router.push({name:'togetherShop',query:{dateType:dateText.value,dateMin:reqTimeMin.value,dateMax:reqTimeMax.value}})
      }else{
        if(val.fff){
          for(let i = 0;val.shopId&&i<val.shopId.length;i++){
            if(val.shopId[i] !== undefined){
              val.shopId = val.shopId[i]
              val.shopName = val.shopName[i]
              break;
            }
          }
        }
        if(val.shopName && val.shopId){
          shopNameQuery = val.shopName
          shopIdQuery = val.shopId
        }else{
          val.shopName = shopNameQuery
          val.shopId = shopIdQuery
        }
        router.push({name:'aloneShop',query:{shopName:val.shopName,shopId:val.shopId,dateType:dateText.value,dateMin:reqTimeMin.value,dateMax:reqTimeMax.value,titleDate:val.titleDate}})
      }
    }
    watch(()=>shopValue.value,()=>{
      if(shopValue.value !== '全部店铺'){
        selectCom.value = MoreSelect
      }else{
        selectCom.value = MoreSelect
      }
    })
    watch(dateText,(val)=>{
      switch(val){
        case '日':
            selectCom.value = MoreSelect
          break;
        case '班次':
          selectCom.value = ClassSelect
          break
        case '周':
          selectCom.value = WeekSelect
          break;
        case '月':
          selectCom.value = MonthSelect
          break;
        case '季':
          selectCom.value = SeaSelect
          break;
        case '年':
          selectCom.value = OneSelect
          break;
        case '自定义':
          selectCom.value = Custom
          break;
        default:
          selectCom.value = MoreSelect
          dateText.value = '日'
          break;
      }
    })
    let titleDate = ref(route.query.titleDate?route.query.titleDate:'')
    const closeSelect=(val)=>{
      dialogVisible.value = false
      if(val.statue){
        switch(val.type){
          case '班次':
            reqTime.value = '班次' + val.reqTimeMin
            reqTimeMax.value = ''
            titleDate.value = val.titleDate
            break;
          case '日':
            reqTime.value = val.reqTimeMin
            if(val.reqTimeMax) reqTime.value += '至' + val.reqTimeMax
            break;
          case '周':
            reqTime.value = val.reqTimeMin
            if(val.reqTimeMax) reqTime.value += '至' + val.reqTimeMax
            break;
          case '月':
            reqTime.value = val.reqTimeMin
            if(val.reqTimeMax) reqTime.value += '至' + val.reqTimeMax
            break;
          case '季':
            reqTime.value = val.reqTimeMin
            if(val.reqTimeMax) reqTime.value += '至' + val.reqTimeMax
            break;
          case '年':
            reqTime.value = val.reqTimeMin
            break;
          case '自定义':
            reqTime.value = (val.value[0].getFullYear()) + '-' + (val.value[0].getMonth() + 1) + '-' + (val.value[0].getDate()) + ' 至 ' +(val.value[1].getFullYear()) + '-' + (val.value[1].getMonth() + 1) + '-' + (val.value[1].getDate())
            break;
          default:
            selectCom.value = val.value
            dateText.value = '日'
            break;
        }
        if(val.type !== '自定义'){
          reqTimeMin.value = val.reqTimeMin
          if(val.reqTimeMax)
          reqTimeMax.value = val.reqTimeMax
        }else if(val.type !== '班次' && val.type !== '自定义'){
          reqTimeMin.value = val.reqTimeMin
        }else{
          reqTimeMin.value = (val.value[0].getFullYear()) + '-' + ((val.value[0].getMonth() + 1)>9?(val.value[0].getMonth() + 1):'0'+(val.value[0].getMonth() + 1)) + '-' + ((val.value[0].getDate())>9?(val.value[0].getDate()):'0'+(val.value[0].getDate()))
          reqTimeMax.value = (val.value[1].getFullYear()) + '-' + ((val.value[1].getMonth() + 1)>9?(val.value[1].getMonth() + 1):'0'+(val.value[1].getMonth() + 1)) + '-' + ((val.value[1].getDate())>9?(val.value[1].getDate()):'0'+(val.value[1].getDate()))
        }   
        if(route.query.shopId) val.shopId = route.query.shopId   
        if(route.query.shopName) val.shopName = route.query.shopName
        routerTo(val)
        changeDateSub(val)
      }
    }
    //改变下标
    const changeDateSubFun = (j,val)=>{
      if(dateBoxArr[j],dateBoxArr[j].data)
      for(let i = 0;i<dateBoxArr[j].data.length;i++){
        if(dateBoxArr[j].data[i].reqTimeMin == val.reqTimeMin){
          dateBoxArr[j].sub = i
        }
      }
    }
    const changeDateSub = (val)=>{
      switch(val.type){
        case '日':
          changeDateSubFun(1,val)
          break
        case '周':
          changeDateSubFun(2,val)
          break
        case '月':
          changeDateSubFun(3,val)
          break
        case '季':
          changeDateSubFun(4,val)
          break
        case '年':
          changeDateSubFun(5,val)
          break
        case '班次':
          val.reqTimeMin = (val.reqTimeMin+'').split(',')[0]
          changeDateSubFun(0,val)
          break
      }
    }
    // 初始化列表
    const infoShop = async () => {
      let param = {mobile:window.localStorage.getItem('mobile')}
      let url = 'shop/getChainShop.json'
      // param.shopIds = window.localStorage.getItem('shopIds')
      // param.mobile = ''
      // if(window.localStorage.getItem('shopIds').split(",").length>1){
      // }
      try {
        let res = await postData(url,param)
        window.sessionStorage.setItem('shopList',JSON.stringify(res.data))
        res.data.forEach(e => {
          shopList.value.push({value:e.shopId,label:e.shopName})
        });
      } catch (err) {
        console.log(err)
      }
    }
    let dateBoxArr =[
        {'data': [],'sub' : -1},
        {'data': [],'sub' :-1},
        {'data': [],'sub': -1},
        {'data': [],'sub' : -1},
        {'data': [],'sub': -1},
        {'data': [],'sub':1}
      ]
    const infoClassData = async()=>{
    let param = {
          shopId:route.query.shopId,
          pageSize:100,
          pageNo:1,
          year:new Date().getFullYear()-1
        }
        try {
          let res = await postData('shop/dutys.json',param)
          res.data.records.forEach(e => {
            e.state = false
            e.reqTimeMin = e.dutyId
            e.data = e.reqTimeMin +'至'+ e.reqTimeMax
          });
          dateBoxArr[0].data = res.data.records
        } catch (err) {
          console.log(err)
        }
    }
    infoShop()
    // 加载季度、年的数据
    const seaAddVuex = ()=>{
      let year = new Date().getFullYear()
      let dateYear = year - (+(window.sessionStorage.getItem('hisdataYear'))+1)
      let dateData = [{reqTimeMin:year + '-01-01',reqTimeMax:year + '-03-31',date:year + '-01-01 至 '+year + '-03-31'}]
      for(let i = 1; i <= dateYear; i++){
        let data = [{reqTimeMin:year-i + '-10-01',reqTimeMax:year-i + '-12-31',date:year-i + '-10-01 至 '+(year-i) + '-12-31'},
          {reqTimeMin:year-i + '-07-01',reqTimeMax:year-i + '-09-30',date:year-i + '-07-01 至 '+(year-i) + '-09-30'},
          {reqTimeMin:year-i + '-04-01',reqTimeMax:year-i + '-06-30',date:year-i + '-04-01 至 '+(year-i) + '-06-30'},
          {reqTimeMin:year-i + '-01-01',reqTimeMax:year-i + '-03-31',date:year-i + '-01-01 至 '+(year-i) + '-03-31'}]
        dateData.push(...data)
      }
      if(new Date().getMonth() >= 9){
        dateData.unshift({reqTimeMin:year + '-04-01',reqTimeMax:year + '-06-30',date:year + '-04-01至'+year + '-06-30'})
        dateData.unshift({reqTimeMin:year + '-07-01',reqTimeMax:year + '-09-30',date:year + '-07-01至'+year + '-09-30'})
        dateData.unshift({reqTimeMin:year + '-10-01',reqTimeMax:year + '-12-31',date:year + '-10-01至'+year + '-12-31'}) 
      }else if(new Date().getMonth() >= 6){
        dateData.unshift({reqTimeMin:year + '-04-01',reqTimeMax:year + '-06-30',date:year + '-04-01至'+year + '-06-30'})
        dateData.unshift({reqTimeMin:year + '-07-01',reqTimeMax:year + '-09-30',date:year + '-07-01至'+year + '-09-30'})
      }else if(new Date().getMonth() >= 3){
        dateData.unshift({reqTimeMin:year + '-04-01',reqTimeMax:year + '-06-30',date:year + '-04-01至'+year + '-06-30'})
      }
      dateBoxArr[4].data = dateData

      let yearDataDate = [
        {
          reqTimeMin:new Date().getFullYear(),
          reqTimeMax:new Date().getFullYear(),
          date:(new Date().getFullYear())+'-01-01 至 '+ new Date().getFullYear()+ '-' +((new Date().getMonth()+1)>9?(new Date().getMonth()+1):('0'+(new Date().getMonth()+1)))+ '-' +((new Date().getDate())>9?(new Date().getDate()):('0'+(new Date().getDate())))
        }]
      let dateYear2 = year - 2016
      for(let i = 1; i <= dateYear2; i++){
        let data = {reqTimeMin:new Date().getFullYear()-i,reqTimeMax:new Date().getFullYear()-i,date:(new Date().getFullYear()-i)+'-01-01 至 '+(new Date().getFullYear()-i)+'-12-31'}
        yearDataDate.push(data)
      }
      dateBoxArr[5].data = yearDataDate
    }
    seaAddVuex()
    // 将月的数据添加到vuex
    const monthAddVuex  = ()=>{
    let a = new Date()
    let c = new Date(+(window.sessionStorage.getItem('hisdataYear'))+1 + '-01-01')
    let b = []
    for(let i = a.getFullYear();i>=c.getFullYear();i--){
      if(i === a.getFullYear()){
        for(let j = a.getMonth()+1;j>0;j--){
          if(j === 2){
            b.push({reqTimeMin:i +'-' + (j>9?j:'0'+j)+'-01',reqTimeMax:i +'-' + (j>9?j:'0'+j)+'-28',data:i +'-' + (j>9?j:'0'+j)+'-01' +' 至 '+i +'-' + (j>9?j:'0'+j)+'-28'})
          }else if(j === 4 || j=== 6 || j=== 9 || j === 11 ){
            b.push({reqTimeMin:i +'-' + (j>9?j:'0'+j)+'-01',reqTimeMax:i +'-' + (j>9?j:'0'+j)+'-30',data:i +'-' + (j>9?j:'0'+j)+'-01' +' 至 '+i +'-' + (j>9?j:'0'+j)+'-30'})
          }else{
            b.push({reqTimeMin:i +'-' + (j>9?j:'0'+j)+'-01',reqTimeMax:i +'-' + (j>9?j:'0'+j)+'-31',data:i +'-' + (j>9?j:'0'+j)+'-01' +' 至 '+i +'-' + (j>9?j:'0'+j)+'-31'})
          }
        }
      }else{
        for(let j = 12;j>0;j--){
          if(j === 2){
            b.push({reqTimeMin:i +'-' + (j>9?j:'0'+j)+'-01',reqTimeMax:i +'-' + (j>9?j:'0'+j)+'-28',data:i +'-' + (j>9?j:'0'+j)+'-01' +' 至 '+i +'-' + (j>9?j:'0'+j)+'-28'})
          }else if(j === 4 || j=== 6 || j=== 9 || j === 11 ){
            b.push({reqTimeMin:i +'-' + (j>9?j:'0'+j)+'-01',reqTimeMax:i +'-' + (j>9?j:'0'+j)+'-30',data:i +'-' + (j>9?j:'0'+j)+'-01' +' 至 '+i +'-' + (j>9?j:'0'+j)+'-30'})
          }else{
            b.push({reqTimeMin:i +'-' + (j>9?j:'0'+j)+'-01',reqTimeMax:i +'-' + (j>9?j:'0'+j)+'-31',data:i +'-' + (j>9?j:'0'+j)+'-01' +' 至 '+i +'-' + (j>9?j:'0'+j)+'-31'})
          }
        }
      }
    }
      dateBoxArr[3].data = b
    }
    monthAddVuex()
    // 将周的数据添加到vuex
    const weeekAddVuex  = ()=>{
      let yearNum = new Date().getFullYear() - window.sessionStorage.getItem('hisdataYear')
      let a = new Date()
      let time = a.getFullYear()+'-'+((a.getMonth()+1)>9?(a.getMonth()+1):('0'+(a.getMonth()+1)))+'-'+(a.getDate()>9?a.getDate():'0'+a.getDate())
      let dateList = []
      const timeWeek = (param)=>{
        switch(new Date(param.reqTimeMin).getMonth()){
          case 0 :
            param.reqTimeMin = (new Date(time).getFullYear()-1)+'-12-31'
            break;
          case 1 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):'0'+(new Date(time).getMonth()))+'-31'
            break;
          case 2 :
            var year = new Date(time).getFullYear()
            if(year%4 == 0 && year%100 != 0 || year%400 == 0){
              param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):'0'+(new Date(time).getMonth()))+'-29'
            }else{
              param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):'0'+(new Date(time).getMonth()))+'-28'
            }
            break;
          case 3 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):'0'+(new Date(time).getMonth()))+'-31'
            break;
          case 4 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):'0'+(new Date(time).getMonth()))+'-30'
            break;
          case 5 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):'0'+(new Date(time).getMonth()))+'-31'
            break;
          case 6 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):'0'+(new Date(time).getMonth()))+'-30'
            break;
          case 7 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):'0'+(new Date(time).getMonth()))+'-31'
            break;
          case 8 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):'0'+(new Date(time).getMonth()))+'-31'
            break;
          case 9 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):'0'+(new Date(time).getMonth()))+'-30'
            break;
          case 10 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):'0'+(new Date(time).getMonth()))+'-31'
            break;
          case 11 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):'0'+(new Date(time).getMonth()))+'-30'
            break;
          default : 
            param.reqTimeMin =  new Date(time).getFullYear()+'-'+((new Date(time).getMonth()+1)>9?(new Date(time).getMonth()+1):'0'+(new Date(time).getMonth()+1))+'-'+((new Date(time).getDate()-1)>9?(new Date(time).getDate()-1):'0'+(new Date(time).getDate()-1))
            break
        }
        return param.reqTimeMin
      }
      const forDate = (param,forNum)=>{
        for(let i = 1;i < forNum;i++){
          if(new Date(param.reqTimeMin).getDate() !== 1){
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth()+1)>9?(new Date(time).getMonth()+1):('0'+(new Date(time).getMonth()+1)))+'-'+((new Date(time).getDate()-1)>9?(new Date(time).getDate()-1):'0'+(new Date(time).getDate()-1))
            time = param.reqTimeMin
            if(i === 6 && new Date(time).getDate() != 1){
              time = new Date(time).getFullYear()+'-'+((new Date(time).getMonth()+1)>9?(new Date(time).getMonth()+1):('0'+(new Date(time).getMonth()+1)))+'-'+((new Date(time).getDate()-1)>9?(new Date(time).getDate()-1):'0'+(new Date(time).getDate()-1))
            }else if(i === 6 && new Date(time).getDate() == 1){
              time = timeWeek(param)
            }
          }else{
            param.reqTimeMin = time = timeWeek(param)
            if(i === 6){
              time = new Date(time).getFullYear()+'-'+((new Date(time).getMonth()+1)>9?(new Date(time).getMonth()+1):('0'+(new Date(time).getMonth()+1)))+'-'+((new Date(time).getDate()-1)>9?(new Date(time).getDate()-1):'0'+(new Date(time).getDate()-1))
            }
          }
        }
      }
      let param = {reqTimeMin:'',reqTimeMax:'',data:''}
      param.reqTimeMax = time
      param.reqTimeMin = time
      switch(new Date(time).getDay()){
        case 0 :
          forDate(param,7)
          break;
        case 1 :
          param.reqTimeMin = time
          break;
        case 2 :
          forDate(param,2)
          break;
        case 3 :
          forDate(param,3)
          break;
        case 4 :
          forDate(param,4)
          break;
        case 5 :
          forDate(param,5)
          break;
        case 6 :
          forDate(param,6)
          break;
        default : 
          param.reqTimeMin = time
      }
      param.data = param.reqTimeMin+'至'+param.reqTimeMax
      dateList.push(param)

      while(new Date(time).getFullYear()>a.getFullYear()-yearNum){
        let param = {reqTimeMin:'',reqTimeMax:'',data:''}
        param.reqTimeMax = time
        param.reqTimeMin = time
        forDate(param,7)
        param.data = param.reqTimeMin+'至'+param.reqTimeMax
        dateList.push(param)
      }
      store.commit('setWeek',dateList)
      dateBoxArr[2].data = dateList
    }
    weeekAddVuex()
    // 将日的数据添加到vuex
    const dataAddVuex  = ()=>{
      let yearNum = new Date().getFullYear() - window.sessionStorage.getItem('hisdataYear')
      let a = new Date()
      let time = a.getFullYear()+'-'+((a.getMonth()+1)>9?(a.getMonth()+1):('0'+(a.getMonth()+1)))+'-'+(a.getDate()>9?a.getDate():'0'+a.getDate())
      let dateList = []
      const timeDate = (param)=>{
        switch(new Date(param.reqTimeMin).getMonth()){
          case 0 :
            param.reqTimeMin = (new Date(time).getFullYear()-1)+'-12-31'
            break;
          case 1 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
            break;
          case 2 :
            var year = new Date(time).getFullYear()
            if(year%4 == 0 && year%100 != 0 || year%400 == 0){
              param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-29'
            }else{
              param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-28'
            }
            break;
          case 3 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
            break;
          case 4 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-30'
            break;
          case 5 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
            break;
          case 6 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-30'
            break;
          case 7 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
            break;
          case 8 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
            break;
          case 9 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-30'
            break;
          case 10 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-31'
            break;
          case 11 :
            param.reqTimeMin = new Date(time).getFullYear()+'-'+((new Date(time).getMonth())>9?(new Date(time).getMonth()):('0'+(new Date(time).getMonth())))+'-30'
            break;
          default : 
            param.reqTimeMin =  time
            break
        }
        return param.reqTimeMin
      }
      let pagNum = -1
      while(new Date(time).getFullYear()>a.getFullYear()-yearNum){
        let param = {reqTimeMin:'',reqTimeMax:'',data:'',state:false,pagNum}
        param.data = param.reqTimeMin = param.reqTimeMax = time
        if(pagNum !== -1){
          if(new Date(param.reqTimeMin).getDate() !== 1){
            param.data = param.reqTimeMin = param.reqTimeMax = time = new Date(time).getFullYear()+'-'+((new Date(time).getMonth()+1)>9?(new Date(time).getMonth()+1):('0'+(new Date(time).getMonth()+1)))+'-'+((new Date(time).getDate()-1)>9?(new Date(time).getDate()-1):'0'+(new Date(time).getDate()-1))
          }else{
            param.data = param.reqTimeMin = param.reqTimeMax = time = timeDate(param)
          }
        }
        pagNum++
        if(pagNum>=13) pagNum = 0
        param.pagNum = pagNum
        dateList.push(param)
      }
      dateList.pop()
      store.commit('setData',dateList)
      dateBoxArr[1].data = dateList
    }
    dataAddVuex()
    
    // 切换班次下标
    const dateArrState = sub =>{
      for(let i = 0;i<dateBoxArr.length;i++){
        if(!i == sub)
        dateBoxArr[i].sub = -1
      }
    }
    // 切换班次
    const cutClass =  (state)=>{
      if(route.query.shopName){
        shopValue.value = route.query.shopName
      }
      dateArrState(0)
      let val = {
        statue:1,
        reqTimeMin: "",
        dateType: "班次",
        type: "班次"
      }
      // val.dateType = route.query.dateType
      if(state && dateBoxArr[0].sub>0){
        --dateBoxArr[0].sub
        let data = dateBoxArr[0].data[dateBoxArr[0].sub]
        val.reqTimeMin = data.reqTimeMin
        titleDate.value = data.beginTime + '至' + (data.endTime?data.endTime:'')
        val.titleDate =titleDate.value
      }else if(!state && dateBoxArr[0].sub<dateBoxArr[0].data.length -1){
        ++dateBoxArr[0].sub
        let data = dateBoxArr[0].data[dateBoxArr[0].sub]
        val.reqTimeMin = data.reqTimeMin
        titleDate.value =  data.beginTime + '至' + (data.endTime?data.endTime:'')
        val.titleDate =titleDate.value
      }else{
        return
      }
      reqTime.value = '班次:'+val.reqTimeMin
      dateText.value = val.type
      reqTimeMin.value = val.reqTimeMin
      routerTo(val)
    }
    // 切换日期
    const cutDate =  (state)=>{
      if(route.query.shopName){
        shopValue.value = route.query.shopName
      }
      let val = {
        statue:1,
        reqTimeMin: "",
        reqTimeMax: "",
        dateType: "日",
      }
      switch(route.query.dateType){
          case '日':
            dateArrState(1)
            val.dateType = route.query.dateType
            if(state && dateBoxArr[1].sub>0){
              dateBoxArr[1].sub-=1
              val.reqTimeMin = dateBoxArr[1].data[dateBoxArr[1].sub].reqTimeMin
              val.reqTimeMax = dateBoxArr[1].data[dateBoxArr[1].sub].reqTimeMax
            }else if(!state && dateBoxArr[1].sub<dateBoxArr[1].data.length -1){
              dateBoxArr[1].sub+=1
              val.reqTimeMin = dateBoxArr[1].data[dateBoxArr[1].sub].reqTimeMin
              val.reqTimeMax = dateBoxArr[1].data[dateBoxArr[1].sub].reqTimeMax
            }else{
              return
            }
            reqTime.value = val.reqTimeMin
            reqTime.value = val.reqTimeMax
            break;
          case '周':
            dateArrState(2)
            val.dateType = route.query.dateType
            if(state && dateBoxArr[2].sub>0){
              dateBoxArr[2].sub-=1
              val.reqTimeMin = dateBoxArr[2].data[dateBoxArr[2].sub].reqTimeMin
              val.reqTimeMax = dateBoxArr[2].data[dateBoxArr[2].sub].reqTimeMax
            }else if(!state && dateBoxArr[2].sub<dateBoxArr[2].data.length -1){
              dateBoxArr[2].sub+=1
              val.reqTimeMin = dateBoxArr[2].data[dateBoxArr[2].sub].reqTimeMin
              val.reqTimeMax = dateBoxArr[2].data[dateBoxArr[2].sub].reqTimeMax
            }else{
              dateArrState(2)
              return
            }
            reqTime.value = val.reqTimeMin
            if(val.reqTimeMax) reqTime.value += '至' + val.reqTimeMax
            break;
          case '月':
            dateArrState(3)
            val.dateType = route.query.dateType
            if(state && dateBoxArr[3].sub>0){
              dateBoxArr[3].sub-=1
              val.reqTimeMin = dateBoxArr[3].data[dateBoxArr[3].sub].reqTimeMin
              val.reqTimeMax = dateBoxArr[3].data[dateBoxArr[3].sub].reqTimeMax
            }else if(!state && dateBoxArr[3].sub<dateBoxArr[3].data.length -1){
              dateBoxArr[3].sub+=1
              val.reqTimeMin = dateBoxArr[3].data[dateBoxArr[3].sub].reqTimeMin
              val.reqTimeMax = dateBoxArr[3].data[dateBoxArr[3].sub].reqTimeMax
            }else{
              return
            }
            reqTime.value = val.reqTimeMin
            if(val.reqTimeMax) reqTime.value += '至' + val.reqTimeMax
            break;
          case '季':
            dateArrState(4)
            val.dateType = route.query.dateType
            if(state && dateBoxArr[4].sub>0){
              dateBoxArr[4].sub-=1
              val.reqTimeMin = dateBoxArr[4].data[dateBoxArr[4].sub].reqTimeMin
              val.reqTimeMax = dateBoxArr[4].data[dateBoxArr[4].sub].reqTimeMax
            }else if(!state && dateBoxArr[4].sub<dateBoxArr[4].data.length -1){
              dateBoxArr[4].sub+=1
              val.reqTimeMin = dateBoxArr[4].data[dateBoxArr[4].sub].reqTimeMin
              val.reqTimeMax = dateBoxArr[4].data[dateBoxArr[4].sub].reqTimeMax
            }else{
              return
            }
            reqTime.value = val.reqTimeMin
            if(val.reqTimeMax) reqTime.value += '至' + val.reqTimeMax
            break;
          case '年':
            dateArrState(5)
            val.dateType = route.query.dateType
            if(state && dateBoxArr[5].sub>0){
              dateBoxArr[5].sub-=1
              val.reqTimeMin = dateBoxArr[5].data[dateBoxArr[5].sub].reqTimeMin
            }else if(!state && dateBoxArr[5].sub<dateBoxArr[5].data.length -1){
              dateBoxArr[5].sub+=1
              val.reqTimeMin = dateBoxArr[5].data[dateBoxArr[5].sub].reqTimeMin
            }else{
              return
            }
            reqTime.value = val.reqTimeMin
            break;
      }
      dateText.value = val.dateType
      reqTimeMin.value = val.reqTimeMin
      reqTimeMax.value = val.reqTimeMax
      val.shopName = route.query.shopName?route.query.shopName:'',
      val.shopId = route.query.shopId?route.query.shopId:'',
      routerTo(val)
    }
    // 左右点击规则
    const clickCut = state=>{
      if(route.query.dateType){
        switch(route.query.dateType){
          case '班次':
            cutClass(state)
            break
          case '自定义':
            return
          default :
            cutDate(state)
          break;
        }
        //初始化的时候
      }else if(!state){
        dateBoxArr[1].sub = 1
        let val = {
          statue:1,
          reqTimeMin:dateBoxArr[1].data[dateBoxArr[1].sub].reqTimeMin,
          reqTimeMax: "",
          type: "日"
        }
        reqTime.value = val.reqTimeMin
        dateText.value = val.type
        reqTimeMin.value = val.reqTimeMin
        reqTimeMax.value = val.reqTimeMax
        routerTo(val)
      }
    }
    if(route.query.shopId){
      infoClassData()
    }
    const dialogVisibleClick = ()=>{
      dateText.value = '日'
      dialogVisible.value = true
    }
    return {
      closeSelect,
      shopValue,
      shopList,
      dateList1,
      shopName,
      dialogVisible,
      dateList,
      dateText,
      selectCom,
      reqTime,
      reqTimeFun,
      selectChangFun,
      selectState,
      headerState,
      clickCut,
      dialogVisibleClick,
      // changeFun,
      titleDate
    }
  },
})
</script>

<style scoped>
header{
  display: flex;
  /* flex-direction: ; */
  justify-content: space-between;
}
.shop-name{
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  text-align: left;
  line-height: 40px;

}
/* main>div{
  margin-bottom: 40px;
} */
.main-header{
  height: 65px;
  line-height: 65px;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
    background-color: #E4E4E4;
}
.main-title{
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
  font-weight: 700;
  font-style: normal;
  font-size: 19px;
  text-align: left;
}
.add{
  color: #169BD5;
  font-size: 16px;
}
.open-select{
  /* width:40px; */
  border:1px solid #E4E4E4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 50px;
  position: relative;
}
.select-bottom{
  font-size: 18px;
  font-weight: 700;
  width:40px;
  height:100%;
  line-height:200%;
  text-align:center;
  border-top:1px solid #E4E4E4;
  position: relative;
  cursor: pointer;
  transform:rotate(-90deg);
}
.top-text-hint{
  font-size: 13px;
  color: #f40;
  margin: 10px 0 20px 0;
}
.selese-top-hint{
  display: flex;
  align-items: center;
}
.flex1{
  flex: 1;
}
</style>
